<template>
  <CustomHeader class="min-h-screen">
    <template #header>
      <div v-if="!isDomain" class="h-full screen relative overflow-hidden">
        <span
          class="absolute -translate-y-1/2 translate-x-1/2 top-1/2 right-1/2 text-lg font-bold"
        >{{ material?.name }}</span>
        <MevcutLink
          :key="cart.items.length"
          class="absolute -translate-y-1/2 top-1/2 rtl:left-2 ltr:right-2"
          to="/cart"
        >
          <Transition name="bounce">
            <MevcutLink to="/cart">
              <div class="relative">
                <v-icon :size="40" class="text-bg_color textShadow">
                  <IconsCartBottomNavigation />
                </v-icon>
                <div
                  class="absolute top-2 left-1/2 -translate-x-1/3 text-button_color mb-1 font-medium"
                >
                  {{ cart.badgeCount }}
                </div>
              </div>
            </MevcutLink>
          </Transition>
        </MevcutLink>
      </div>
    </template>
    <template #body>
      <div v-if="material">
        <div class="mt-4" :class="{ 'mevcut-container': !isMobileOrTablet }">
          <div
            class="flex flex-col md:flex-row rounded-md overflow-hidden shadow bg-bg_alt_color mb-5"
          >
            <MaterialsShowImageSlider
              :key="`ImageSlider-${material.id}`"
              :material="material"
            />
            <MaterialsShowMeta
              class="md:w-1/2 lg:ltr:pl-10 lg:rtl:pr-10"
              :material="material"
              @add-addons-attribute="addAddonsAttributesToMaterial"
              @refresh-page="updateMaterials"
            />
          </div>
          <MaterialsShowShareNetwork :material="material" />
          <MaterialsShowListRates
            v-if="!isDomain"
            :key="keyRatesReview"
            :material="material"
          />

          <HomeOffersGroupSlider :query="{ 'material_ids[]': [material.id] }" />

          <RikazGrid
            v-if="likeMaterials?.length"
            name="similar"
            class="mb-5"
            :items="likeMaterials"
            margin="ltr:-ml-3.5 rtl:-mr-3.5"
          >
            <template #title>
              <div class="flex justify-center my-2">
                <div class="mevcut-title">{{ t('like_materials') }}</div>
              </div>
            </template>
            <template #card="{ forKey, data }">
              <MaterialsTile
                v-if="store?.one_line_material"
                :key="`similar-grid-tile-${forKey}`"
                class="transition-all duration-500 transform hover:scale-105"
                :material-props="(data as Material)"
              />
              <MaterialsCard
                v-else
                :key="`similar-grid-card-${forKey}`"
                class-card="w-[8rem] xs:w-[9rem] sm:w-[13.5rem]"
                class="transition-all duration-500 transform hover:scale-105"
                :material-props="(data as Material)"
              />
            </template>
          </RikazGrid>
          <HomeAdSecondarySwiper :ads="{ items: ads, error: errorAds }" />
        </div>
      </div>
    </template>
  </CustomHeader>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import type { Material, Ad } from '~~/composables/useMenuModel'
const { isMobileOrTablet } = useDevice()
const { materialModel, adModel } = useMenuModel()
const isDomain = useIsDomain()
const route = useRoute()
const emit = defineEmits(['update-material'])
const keyRatesReview = ref<number>(Math.random())
const currentBranchId = useBranchId()
const { t } = useI18n()
const props = defineProps<{
  fetchRoute: string
}>()

const { store } = useDomainState()

const { data: material, error } = await fetchMaterial()

useApiError(error.value)

emit('update-material', material.value)

function fetchMaterial(newIdMaterial?: number) {
  return useBasicFetch(props.fetchRoute, {
    query: {
      id: newIdMaterial || route.params.id,
      branch_id: currentBranchId
    },

    transform: (data: SuccessResponse<Material>) => {
      return materialModel(data.data)
    }
  })
}

const cart = useCartStore()

async function updateMaterials(event: any) {
  const { data: updateMaterial, error: updateError } = await fetchMaterial(
    event
  )

  material.value = updateMaterial.value

  useApiError(updateError.value)

  emit('update-material', updateMaterial.value)

  keyRatesReview.value = Math.random()
}

const { data: ads, error: errorAds } = await fetchAnnouncements()

function fetchAnnouncements() {
  if (!material.value?.store_id) {
    return { data: null, error: null }
  }

  return useBasicFetch(
    getAllRoutes().announcementRoutes.announcementsStore(
      material.value?.store_id
    ),
    {
      query: { branch_id: currentBranchId },
      transform: (
        data: SuccessResponse<{
          announcementsStore: Ad[]
          announcements: Ad[]
        }>
      ) => {
        return [
          ...(isDomain ? [] : data.data.announcements.map(adModel)),
          ...data.data.announcementsStore.map(adModel)
        ]
      }
    }
  )
}

const likeMaterials = computed(() => {
  return material.value?.likeMaterials?.slice(0, 4)
})

const addAddonsAttributesToMaterial = (extraAttribute: {
  name: string
  desc: string
  price: string
}) => {
  const index =
    material.value?.addons?.findIndex(
      (el) => el.name === extraAttribute.name && el.desc === extraAttribute.desc
    ) || -1
  let attributes = []
  if (index >= 0) {
    attributes =
      material.value?.addons?.filter((el) => el.name !== extraAttribute.name) ||
      []
  } else {
    attributes = [...(material.value?.addons || []), extraAttribute]
  }
  material.value = {
    ...material.value,
    addons: attributes
  } as any
}
</script>
